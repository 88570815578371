@media only screen and (max-width: 600px) {
  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }

  .video-responsive iframe {
    width: 100%;
    height: auto;
  }
}
