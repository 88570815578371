tr > td:first-child {
  background-color: #183d6f;
  color: #fff;
}

td {
  padding: 4px 8px;
  border: 1px solid #183d6f;
}

table {
  border-collapse: collapse;
}
